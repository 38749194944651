body {
  margin: 0;
  padding: 0;
  background-color: red;

}

.lottie-container {
  width: 80% !important;
  max-width: 600px !important;
  min-height: 400px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .lottie-container {
    min-height: 200px;
    max-width: 320px;
  }

  .mobile-hide {
    display: none;
  }
  .no-margin-on-mobile {
    margin-top: 0px !important;
  }
}

@media (min-width: 768px) {
  
  .mobile-only {
    display: none;
  }
}


.quotes-wrapper {
  margin: 24px 0 !important;
}

.quotes-wrapper a {
  color: yellow !important; 
}